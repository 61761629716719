<template>
  <div class="commission-record">
    <h2>佣金记录</h2>
  </div>
</template>

<script>
export default {
  name: 'CommissionRecord'
}
</script> 